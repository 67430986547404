exports.components = {
  "component---configurator-src-pages-app-configurator-js": () => import("./../../../../configurator/src/pages/app/configurator.js" /* webpackChunkName: "component---configurator-src-pages-app-configurator-js" */),
  "component---configurator-src-pages-de-app-konfigurator-js": () => import("./../../../../configurator/src/pages/de/app/konfigurator.js" /* webpackChunkName: "component---configurator-src-pages-de-app-konfigurator-js" */),
  "component---configurator-src-pages-fr-app-configurateur-js": () => import("./../../../../configurator/src/pages/fr/app/configurateur.js" /* webpackChunkName: "component---configurator-src-pages-fr-app-configurateur-js" */),
  "component---src-templates-404-js": () => import("./../../../src/templates/404.js" /* webpackChunkName: "component---src-templates-404-js" */),
  "component---src-templates-contact-js": () => import("./../../../src/templates/contact.js" /* webpackChunkName: "component---src-templates-contact-js" */),
  "component---src-templates-faq-js": () => import("./../../../src/templates/FAQ.js" /* webpackChunkName: "component---src-templates-faq-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-prices-js": () => import("./../../../src/templates/prices.js" /* webpackChunkName: "component---src-templates-prices-js" */),
  "component---src-templates-process-js": () => import("./../../../src/templates/process.js" /* webpackChunkName: "component---src-templates-process-js" */),
  "component---src-templates-specifications-js": () => import("./../../../src/templates/specifications.js" /* webpackChunkName: "component---src-templates-specifications-js" */),
  "component---src-templates-subscribe-js": () => import("./../../../src/templates/subscribe.js" /* webpackChunkName: "component---src-templates-subscribe-js" */),
  "component---src-templates-successful-subscription-js": () => import("./../../../src/templates/successfulSubscription.js" /* webpackChunkName: "component---src-templates-successful-subscription-js" */),
  "component---src-templates-template-page-js": () => import("./../../../src/templates/templatePage.js" /* webpackChunkName: "component---src-templates-template-page-js" */),
  "component---src-templates-vinylcolors-js": () => import("./../../../src/templates/vinylcolors.js" /* webpackChunkName: "component---src-templates-vinylcolors-js" */),
  "slice---gatsby-theme-objects-src-components-footer-js": () => import("./../../../../gatsby-theme-objects/src/components/Footer.js" /* webpackChunkName: "slice---gatsby-theme-objects-src-components-footer-js" */),
  "slice---gatsby-theme-objects-src-components-navbar-js": () => import("./../../../../gatsby-theme-objects/src/components/Navbar.js" /* webpackChunkName: "slice---gatsby-theme-objects-src-components-navbar-js" */)
}

