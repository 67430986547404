import fetch from 'isomorphic-fetch';
import { ApolloClient, InMemoryCache } from '@apollo/client';

export const client = new ApolloClient({
  uri: `https://api.monday.com/v2`, //THIS IS THE URL OF THR GRAPHQL WE WANTO TO QUERY
  fetch: fetch,
  headers: {
    authorization: process.env.MONDAY_API_V2_TOKEN,
  },
  fetchOptions: {
    mode: 'no-cors',
  },
  // credentials: 'include',
  cache: new InMemoryCache(),
});
