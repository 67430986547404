import { css } from '@emotion/react';
import { colors } from './variables';
// introduces dvh units
import 'large-small-dynamic-viewport-units-polyfill';

export const GlobalStyles = css`
  :root {
    --100dvh: calc(var(--1dvh, 1vh) * 100);
  }

  body,
  html,
  gatsby-focus-wrapper {
    background: ${colors.lightMint};
    -webkit-font-smoothing: antialiased; /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  html::-webkit-scrollbar,
  .scroll-container::-webkit-scrollbar,
  gatsby-focus-wrapper::-webkit-scrollbar {
    display: none;
  }

  body {
    padding: 0;
    margin: 0;
  }

  * {
    font-family: 'Whyte';
    box-sizing: border-box;
  }
  input:focus {
    outline: ${colors.purple} 4px solid;
  }
  ::selection {
    background: transparent;
  }
  p::selection {
    background: ${colors.yellow};
  }

  #___gatsby {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  #gatsby-focus-wrapper {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    width: 100vw;
    overflow-x: hidden;
  }
  main {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  a {
    color: ${colors.black};
  }
`;
