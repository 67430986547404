/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

//Copygatsby-browser.js: copy code to clipboard

import React from 'react';
import { ApolloWrapper } from './src/services/apollo/provider';
import { GlobalStyles } from 'gatsby-theme-objects/src/styles/GlobalStyles';
import { FontStyles } from 'landingpage/src/styles/fontStyles';
import LanguageContextProvider from 'gatsby-theme-objects/src/utils/LanguageContext';
import { Global } from '@emotion/react';

export const wrapRootElement = ({ element }) => (
  <>
    <LanguageContextProvider>{element}</LanguageContextProvider>
  </>
);

export const wrapPageElement = ({ element, ...props }) => (
  <ApolloWrapper>
    <Global styles={GlobalStyles} />
    <Global styles={FontStyles} />
    {element}
  </ApolloWrapper>
);
