import { mediaQueries } from './queries';

export const colors = {
  yellow: '#FEF981',
  neonGreen: '#49F70C',
  lightMint: `#E8ECE1`,
  lighterMint: `#F4F6F0`,
  purple: `#E3C5F9`,
  black: `#000000`,
  darkGray: '#333',
  deepPurple: '#C9C8E8',
};
export const distances = {
  p0: '0.5rem',
  p1: '1rem',
  p2: '2rem',
  p3: '4rem',
  p4: '6rem',
};

export const heights = {
  h1: '3.2rem',
  h2: '4rem',
};

export const widths = {
  logo: '7rem',
  max: '1600px',
};

export const borderRadius = {
  r1: '15px',
  r2: '12px',
  r3: '8px',
  r4: '4px',
};

export const strokeWidth = {
  s1: 2,
};
export const borders = {
  b1: `solid ${strokeWidth.s1}px ${colors.black}`,
};

export const boxShadows = {
  b0: '0px 0px 1px 2px #00000008',
  b1: '1px 4px 12px 3px #00000017',
  b2: '1px 5px 13px 10px #00000010',
  b3: '1px 4px 12px 3px rgba(0, 0, 0, 0.09)',
};

export const transitions = {
  t1: 'all ease-in 0.2s',
  t2: 'all ease-in 0.5s',
  t3: 'all ease-in-out 0.6s',
};

export const fontStyles = {
  header: {
    normal: `
        font-family: 'Clash Display', sans-serif;
        font-size: 6rem;
        font-weight: 600;
        line-height: 110%;
        ${mediaQueries.medium} {
          font-size: 3.5rem;
          line-height: 120%;
        }
`,
    small: `      
        font-family: 'Clash Display', sans-serif;
        font-size: 3.5rem;
        font-weight: 600;
        line-height: 125%;
        `,
  },
  title: {
    big: `
        font-family: 'Whyte', sans-serif;
        font-weight: 700;
        font-size: 3.5rem;
        line-height: 110%;
      `,
    normal: `
        font-family: 'Whyte', sans-serif;
        font-weight: 700;
        font-size: 2.5rem;
        line-height: 115%;
      `,
    small: `
        font-family: 'Whyte', sans-serif;
        font-weight: 700;
        font-size: 1.5rem;
        line-height: 125%;

      `,
    tiny: `
        font-family: 'Clash Display', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 125%;
      `,
  },
  quoted: `
      font-family: 'Whyte', sans-serif;
      font-weight: 400;
      font-size: 2.5rem;
      line-height: 125%;

    `,
  body: {
    normal: `
        font-family: 'Whyte', sans-serif;
        font-weight: 400;
        font-size: 1.3rem;
        line-height: 125%;
      `,
    small: `
        font-family: 'Whyte', sans-serif;
        font-weight: 400;
        font-size: 1rem;
        line-height: 125%;
        $`,
    tiny: `
        font-family: 'Whyte', sans-serif;
        font-weight: 400;
        font-size: 0.8rem;
        line-height: 125%;`,
  },
  button: {
    normal: `
        font-family: 'Clash Display', sans-serif;
        font-weight: 500;
        font-size: 1.5rem;
        line-height: 110%;
    `,
    small: `
        font-family: 'Clash Display', sans-serif;
        font-weight: 500;
        font-size: 1rem;
        line-height: 110%;
    `,
    bold: `
        font-family: 'Clash Display', sans-serif;
        font-weight: 600;
        font-size: 1.5rem;
        line-height: 110%;
    `,
    whyte: `
        font-family: 'Whyte', sans-serif;
        font-weight: 400;
        font-size: 1.5rem;
        line-height: 125%;
      `,
    burger: `
      font-family: 'Clash Display', sans-serif;
      font-weight: 600;
      font-size: 3rem;
      line-height: 120%;
      `,
  },

  ///// PRE LAUNCH /////

  preLaunch: {
    tiny: `
        font-family: 'Whyte', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 140%;`,
    small: `
            font-family: 'Whyte', sans-serif;
            font-weight: 400;
            font-size: 20px;
            line-height: 140%;`,
    medium: `
            font-family: 'Whyte', sans-serif;
            font-weight: 400;
            font-size: 30px;
            line-height: 125%;
            ${mediaQueries.medium} {
              font-size: 25px;
            }`,
    huge: `
            font-family: 'Whyte', sans-serif;
            font-size: 64px;
            font-weight: 700;
            line-height: 115%;
            ${mediaQueries.medium} {
              font-size: 32px;
              line-height: 120%;
            }`,
  },
};
