import React, { useState, createContext, useContext, useMemo } from 'react';
// TO BE USED IN GATSBY PROJECT TO PROVIDE LANGUAGE CONTEXT

const LanguageContextProvider = ({ children }) => {
  // –––––––– change language based on browser language
  const getBrowserLanguage = useMemo(() => {
    // if serverside
    if (typeof navigator === `undefined`) {
      return 'en';
    }

    const lang = typeof navigator !== `undefined` && navigator.language;
    if (lang === 'de' || lang === 'de-DE') {
      return 'de';
    } else if (lang === 'fr' || lang === 'fr-FR') {
      return 'fr';
    } else if (lang === 'en' || lang === 'en-Us' || lang === 'en-GB') {
      return 'en';
    }
    return 'en';
  }, []);
  // ––––––––––
  //

  const [state, setState] = useState({
    lang: getBrowserLanguage,
    wasSwitched: false,
  });

  return (
    <LanguageContext.Provider value={[state, setState]}>
      {children}
    </LanguageContext.Provider>
  );
};

const LanguageContext = createContext();

const useLanguageContext = () => {
  const [state, setState] = useContext(LanguageContext);

  const switchLanguage = currentLang => {
    setState({
      lang: currentLang,
      wasSwitched: true,
    });
  };

  return [state, switchLanguage];
};

export { useLanguageContext };

export default LanguageContextProvider;
