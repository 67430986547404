import { css } from '@emotion/react';

export const FontStyles = css`
  * {
    font-family: 'Whyte', sans-serif;
  }
  @font-face {
    font-family: 'Whyte';
    src: url('/fonts/ABCWhyte-Regular.woff2') format('woff2');
    unicode-range: U+0025-00FF;
    font-weight: 400;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Whyte';
    src: url('/fonts/ABCWhyte-Bold.woff2') format('woff2');
    unicode-range: U+0025-00FF;
    font-weight: 700;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Clash Display';
    src: url('/fonts/ClashDisplay-Medium.woff2') format('woff2');
    unicode-range: U+0025-00FF;
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  @font-face {
    font-family: 'Clash Display';
    src: url('/fonts/ClashDisplay-Semibold.woff2') format('woff2');
    unicode-range: U+0025-00FF;
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
`;
