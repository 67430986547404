import { css } from '@emotion/react';
import {
  borderRadius,
  colors,
  heights,
  distances,
  transitions,
  boxShadows,
  fontStyles,
} from './variables';
import { mediaQueries } from './queries';
// introduces dvh units
import 'large-small-dynamic-viewport-units-polyfill';

export const GlobalStyles = css`
  :root {
    --100dvh: calc(var(--1dvh, 1vh) * 100);
        font-size: 15px;

        ${mediaQueries.wideScreen} {
          font-size: 21px;
        }
        ${mediaQueries.desktop} {
          font-size: 15px;
        }
        ${mediaQueries.medium} {
          font-size: 15px;
        }

        ${mediaQueries.small} {
          font-size: 15px;
        }
  }

  body,html, #gatsby-focus-wrapper {
    background: ${colors.lightMint};
    -webkit-font-smoothing: antialiased; /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;  /* IE and Edge */
    scrollbar-width: none;  /* Firefox */  
    /* height: 100%; */
    /* margin: 0; */
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  html::-webkit-scrollbar, .scroll-container::-webkit-scrollbar, #gatsby-focus-wrapper::-webkit-scrollbar {
    display: none;
  }

  body {
    padding: 0;
    margin: 0;
  }

  * {
    font-family: 'Whyte', sans-serif;
    box-sizing: border-box;
  }
  input:focus {
    outline: ${colors.purple} 4px solid
  }
  ::selection {
    background: transparent;
  }
  input::selection {
        background: ${colors.yellow};
  }
  p::selection {
    background: ${colors.yellow};
  }

  #___gatsby{
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    height: 100%;
  }

  #gatsby-focus-wrapper{
    padding: 0;
    margin: 0;
    box-sizing: inherit;
    width: 100vw;
    overflow-x: hidden;
  }
  main {
    padding: 0;
    margin: 0;
    box-sizing: inherit;
  }

  a {
    color: ${colors.black}
  }

  //https://cookieconsent.orestbida.com/advanced/ui-customization.html
  //needs to be added globally
  #cc-main{
    --cc-font-family: 'Whyte', sans-serif;

    --cc-primary-color:${colors.black};
    --cc-btn-primary-color: ${colors.black};
    --cc-btn-primary-hover-bg: ${colors.yellow};
    --cc-btn-primary-bg: ${colors.deepPurple};
    --cc-btn-primary-hover-bg: ${colors.deepPurple};
    --cc-btn-primary-hover-color:white;

    --cc-secondary-color: ${colors.black};
    --cc-btn-secondary-bg: ${colors.deepPurple};
    --cc-btn-secondary-color: ${colors.black};
    --cc-btn-secondary-hover-bg: ${colors.deepPurple};
    --cc-btn-secondary-hover-color:white;
    --cc-btn-secondary-hover-border-color: #d4dae0;

    --cc-separator-border-color: ${colors.lighterMint};

    --cc-footer-bg: ${colors.lighterMint};
    --cc-footer-color: ${colors.black};
    --cc-footer-border-color: #e4eaed;

    --cc-cookie-category-block-bg: ${colors.deepPurple};
    --cc-cookie-category-block-border: ${colors.deepPurple};
    --cc-cookie-category-block-hover-bg: ${colors.deepPurple};
    --cc-cookie-category-block-hover-border:${colors.deepPurple};
    --cc-cookie-category-expanded-block-bg:${colors.deepPurple};
    --cc-cookie-category-expanded-block-hover-bg: ${colors.deepPurple};
    
    --cc-toggle-off-bg: ${colors.lighterMint};
    --cc-toggle-on-bg: ${colors.yellow};

    --cc-toggle-readonly-bg: ${colors.yellow};


    //&&& for higher specificity
    &&& {
      .cm__body, .pm__body, .pm__header,.pm__footer{
        background:${colors.lightMint}; !important
      }

      .pm__section-arrow{
        background:${colors.lighterMint}; 
      }

      .cm__btn{
        display: flex; 
        align-items: center; 
        justify-content: center; 
      }

      .cm{
        border-radius: ${borderRadius.r1}; 
        box-shadow: ${boxShadows.b1}; 
      }
      
      .pm__section{
        border:none; 
      }

      .pm__section:not(:first-of-type){
        &:hover{
          background:${colors.lightMint}; 
          border:${colors.lightMint}; 
        }
      }

      .toggle__icon-circle{
        .toggle__icon-on,  .toggle__icon-off{
          svg{
            stroke: ${colors.black};
          }
        }
      }

      .cm__btn, button, .pm__btn, .pm__close-btn{
        min-width: ${heights.h2};
        height: ${heights.h2};
        height: auto;
        min-height: ${heights.h2};
        padding: 0 ${distances.p2};
        color: ${colors.black};
        box-shadow: ${boxShadows.b1};
        border: none;
        align-self: self-start;
        border-radius: ${borderRadius.r1};
        cursor: pointer;
        z-index: 1;
        transition: ${transitions.t1};
        ${fontStyles.button.small};

        &:hover {
          box-shadow: ${boxShadows.b2};
          backface-visibility: hidden;
          color:white;
        }

      }

      .pm__close-btn{
        padding:0;
        svg{
          stroke:${colors.black}!Important;
        }
      }


      .pm__section-title{
        border-radius: var(--cc-btn-border-radius);
      }
    }
  }


  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

/* Firefox */
    input[type=number] {
      -moz-appearance: textfield;
    }
`;
