/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.com/docs/browser-apis/
 */

// You can delete this file if you're not using it

//Copygatsby-browser.js: copy code to clipboard

import React from 'react';
import { GlobalStyles } from './src/styles/GlobalStyles';
import { FontStyles } from './src/styles/fontStyles';
import LanguageContextProvider from 'gatsby-theme-objects/src/utils/LanguageContext';
import { Global } from '@emotion/react';

export const shouldUpdateScroll = () => {
  //scroll to top on router change
  window.scrollTo(0, 0);
  return false;
};

export const wrapRootElement = ({ element }) => (
  <>
    <LanguageContextProvider>{element}</LanguageContextProvider>
  </>
);

export const wrapPageElement = ({ element, ...props }) => {
  return (
    <>
      <Global styles={GlobalStyles} />
      <Global styles={FontStyles} />
      {element}
    </>
  );
};
